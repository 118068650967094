import { urls } from '.'
import { ChipButton } from '../components/buttons'
import { BaseGrid, BasePage, Card, Table } from '../components/layout'
import { MessageDrawer, Notification, Tooltip } from '../components/ui'
import { ReactNode, useEffect, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { OrderStatus } from '../types/enums'
import { ApiUtility, Endpoints, FormattingUtility } from '../utilities'
import { RichTextEditor } from '../components'

export default function Page() {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(Store.getNotifications)
    const orders = useAppSelector(Store.getOrders)
    const threads = useAppSelector(Store.getThreads)
    const [selectedThread, setSelectedThread] = useState<string>()
    const [drawerOpen, toggleDrawer] = useState<boolean>(false)

    const MessageColumnDefinitions: GridColDef[] = [
        { field: 'created', headerName: 'Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
        { field: 'name', headerName: 'Subject', flex: 3, },
        { field: 'lastMessage', headerName: 'Last Message', flex: 3, valueGetter: ({ row }) => row.lastMessage || '' },
    ]

    const OrderColumnDefinitions: GridColDef[] = [
        { field: 'created', headerName: 'Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
        { field: 'status', headerName: 'Status', flex: 1, renderCell: ({ row }) => getPrettyStatus(row.status) },
        { field: 'deliveryDate', headerName: 'Delivery Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.deliveryDate) },
        { field: 'salePrice', headerName: 'Cost', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatMoney(row.salePrice, '-') },
        { field: 'notes', headerName: 'Notes', flex: 1, valueGetter: ({ row }) => !!row.notes ? 'Yes' : '-' },
        { field: 'jobName', headerName: 'Job Name', flex: 1, },
        { field: 'jobNumber', headerName: 'Job Number', flex: 1 },
    ]

    const getPrettyStatus = (value: OrderStatus): ReactNode => {
        return (
            <span className='row' style={{ width: '100%' }}>
                <p style={{ color: FormattingUtility.getOrderStatusColor(value), fontWeight: 500 }}>{FormattingUtility.getOrderStatus(value)}</p>
            </span>
        )
    }

    const loadNotifications = async () => {
        const response = await ApiUtility.Get(Endpoints.Notifications)
        if (response.success) {
            dispatch(Store.setNotifications(response.data))
        }
    }

    const clearAllNotifications = async () => {
        const body = {
            uuids: notifications.map(el => el.uuid)
        }
        const response = await ApiUtility.Post(Endpoints.Notifications, body)
        if (response.success) {
            dispatch(Store.setNotifications([]))
        }
    }

    useEffect(() => {
        loadNotifications()
    }, [])

    return (
        <BasePage title='Dashboard' requireAuth={true}>
            {/* A way to quickly test the RichTextEditor */}
            {/* <div className='ui-row grid-container'>
                <div className='grid'>
                    <Card title='RTE Testing'>
                        <RichTextEditor content='Starting out <i>here.</i>' onChange={() => {}} />
                    </Card>
                </div>
            </div> */}
            <div className='ui-row grid-container'>
                <div className='grid'>
                    <Card
                        disableLine
                        title='Notifications'
                        rightHeader={
                            <div className='row flex-center'>
                                {((notifications.length > 0) && <ChipButton text='Clear all' onClick={clearAllNotifications} />)}
                                <Tooltip text="Lists important updates regarding orders." />
                            </div>
                        }
                    >
                        {notifications.length > 0 && (
                            <Table twoTone>
                                {notifications.slice(0, 7).map(el => (
                                    <Notification key={el.uuid} notification={el} showTimestamp={true} />
                                ))}
                            </Table>
                        )}
                        {notifications.length < 1 && (
                            <div className='row flex-center' style={{ height: '100%' }}>
                                <p className='subtle'>No new notifications</p>
                            </div>
                        )}
                    </Card>
                    <Card
                        disableLine
                        rightHeader={<Tooltip text='Lists recent orders that have shipped. Note that, occasionally, an exact Delivery Date may not yet be available.' />}
                        title='Upcoming Deliveries'
                    >
                        <BaseGrid
                            columns={[
                                { field: 'jobNumber', headerName: 'Job Number', flex: 1 },
                                { field: 'status', headerName: 'Status', flex: 1, renderCell: ({ row }) => getPrettyStatus(row.status) },
                                { field: 'deliveryDate', headerName: 'Delivery Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.deliveryDate) },
                            ]}
                            data={orders.filter(el => !!el.deliveryDate && [OrderStatus.Approved, OrderStatus.Shipped].includes(el.status))}
                            idKey='idOrders'
                            hideFooter={true}
                            maxHeight='300px'
                            onRowClick={({ row }) => history.push(urls.orders + row.uuid)}
                        />
                    </Card>
                </div>
            </div>
            <div className='ui-row'>
                <Card
                    disableLine
                    fullWidth
                    rightHeader={<Tooltip text='These are your active orders that have not been shipped or delivered.' />}
                    title='Recent Orders'
                >
                    <BaseGrid
                        columns={OrderColumnDefinitions}
                        data={orders.filter(el => ![OrderStatus.Archived, OrderStatus.Cancelled, OrderStatus.Delivered].includes(el.status))}
                        idKey='idOrders'
                        hideFooter={true}
                        maxHeight='500px'
                        onRowClick={({ row }) => history.push(urls.orders + row.uuid)}
                    />
                </Card>
            </div>
            <div className='ui-row'>
                <Card
                    disableLine
                    fullWidth
                    rightHeader={<Tooltip text='Lists all support and order-related message threads.' />}
                    title='Message Center'
                >
                    <BaseGrid
                        columns={MessageColumnDefinitions}
                        data={threads}
                        idKey='uuid'
                        hideFooter={true}
                        maxHeight='500px'
                        onRowClick={({ row }) => { setSelectedThread(row.uuid); toggleDrawer(true) }}
                    />
                </Card>
            </div>
            <MessageDrawer
                isOpen={drawerOpen}
                onClose={() => toggleDrawer(false)}
                thread={selectedThread}
            />
        </BasePage>
    );
}