import { BaseButton } from '../buttons'
import { Formik } from 'formik'
import { BaseInput, ComboBox, Dropdown, RichTextEditor } from '../inputs'
import { Box, Stack } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as Store from '../../store/slices'
import { DropDownItem, LineItem, Product, Room } from '../../types'
import Data from '../../utilities/data.json'
import * as Yup from 'yup'
import { FormattingUtility } from '../../utilities'

interface CabinetFormProps {
    lineItem: LineItem
    remove: (item: Partial<LineItem>) => void
    room?: Room
}

const schema = Yup.object().shape({
    depth: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    height: Yup.string().required('Required'),
    hingeLocation: Yup.string().required('Required'),
    modifications: Yup.string(),
    quantity: Yup.string().required('Required'),
    sku: Yup.string().required('Required'),
    width: Yup.string().required('Required'),
})

export default function CabinetForm({ lineItem, remove, room }: CabinetFormProps) {
    const dispatch = useAppDispatch()
    const draftOrder = useAppSelector(Store.getDraftOrder)
    const products = useAppSelector<Product[]>(Store.getProducts)
    const skuChanged = (item: DropDownItem | null): LineItem | null => {
        if (!item) {
            // Note: sku was cleared but we're not going to alter the form
            return null
        }

        let product = products.find(product => product.sku === item?.label)
        if (!product) {
            console.error('New SKU not found')
            return null
        }

        let values = {
            depth: product.depth,
            description: product.description,
            height: product.height,
            hingeLocation: '',
            itemNumber: lineItem.itemNumber,
            key: lineItem.key,
            modifications: '',
            quantity: '',
            sku: product.sku,
            width: product.width,
        }
        return values
    }
    const dispatchItemUpdate = (vals: any, isNewSku?: boolean) => {
        if (!vals || !room) {
            return
        }

        let updatedItem = isNewSku ? vals : { ...lineItem, ...vals }
        let items = []
        if (!room.lineItems || room.lineItems.length < 1) {
            items.push(updatedItem)
        } else {
            for (let item of room.lineItems) {
                if (item.key === updatedItem.key) {
                    items.push({ ...updatedItem })
                } else {
                    items.push({ ...item })
                }
            }
        }
        let updatedRoom = { ...room, lineItems: items }
        let rooms = FormattingUtility.updateRoomWithinOrderRooms(draftOrder, updatedRoom)
        dispatch(Store.updateDraftOrder({ rooms: rooms }))
    }
    return (
        <>
            <Formik
                initialValues={lineItem}
                enableReinitialize={true}
                onSubmit={(vals) => { }}
            >
                {({ values, touched, errors, isValid, handleSubmit, handleBlur, handleChange, setValues, submitForm }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={1}>
                            <p style={{ fontSize: '1em' }}>Cabinet {lineItem.itemNumber}</p>
                            <Stack direction='row' spacing={1}>
                                <Box flex={1}>
                                    <ComboBox
                                        defaultValue={values.sku}
                                        fullWidth
                                        items={products.map((product) => { return { key: product.uuid, label: product.sku } })}
                                        name={values.itemNumber}
                                        onSelect={(item) => {
                                            let newLineItem = skuChanged(item)
                                            dispatchItemUpdate(newLineItem, true)
                                        }}
                                        placeholder='SKU'
                                    />
                                </Box>
                                <Box flex={4}>
                                    <BaseInput
                                        name='description'
                                        value={values.description}
                                        error={touched.description && errors.description ? errors.description : ''}
                                        placeholder='Description'
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={(e) => { dispatchItemUpdate({ description: e.target.value }) }}
                                    />
                                </Box>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Stack>
                                    <Stack direction='row' spacing={1}>
                                        <BaseInput name='width' label='Width' placeholder='x"' value={values.width} onBlur={handleBlur} onChange={(e) => { dispatchItemUpdate({ width: e.target.value }) }} />
                                        <BaseInput name='height' label='Height' placeholder='x"' value={values.height} onBlur={handleBlur} onChange={(e) => { dispatchItemUpdate({ height: e.target.value }) }} />
                                        <BaseInput name='depth' label='Depth' placeholder='x"' value={values.depth} onBlur={handleBlur} onChange={(e) => { dispatchItemUpdate({ depth: e.target.value }) }} />
                                    </Stack>
                                    <Stack direction='row' spacing={1}>
                                        <BaseInput name='quantity' label='Qty.' placeholder='Enter qty' value={values.quantity} onBlur={handleBlur} onChange={(e) => { dispatchItemUpdate({ quantity: e.target.value }) }} />
                                        <ComboBox
                                            defaultValue={values.hingeLocation || ''}
                                            fullWidth
                                            items={Data.OrderDetails_CabinetHinges}
                                            label='Hinge Location'
                                            name='hingeLocation'
                                            onSelect={(item) => { dispatchItemUpdate({ hingeLocation: item?.label }) }}
                                            optional
                                        />
                                    </Stack>
                                </Stack>
                                <Box flex={1}>
                                    {/* <BaseInput
                                        name='modifications'
                                        label='Modifications & Accessories'
                                        placeholder='Enter Modifications and accessories here'
                                        value={values.modifications}
                                        error={touched.modifications && errors.modifications ? errors.modifications : ''}
                                        fullWidth
                                        type='textarea'
                                        onBlur={handleBlur}
                                        onChange={(e) => { dispatchItemUpdate({ modifications: e.target.value }) }}
                                    /> */}
                                    <p>Modifications & Accessories <span style={{color: 'red'}}>*</span></p>
                                    <RichTextEditor content={values.modifications} onChange={(val) => { dispatchItemUpdate({ modifications: val }) }} />
                                </Box>
                            </Stack>
                            <Box display='flex' justifyContent='flex-end'>
                                <BaseButton text='Remove' leftIcon='x' variant='delete' onClick={() => remove(lineItem)} style={{ height: 32, padding: 8, marginBottom: 8 }} />
                            </Box>
                        </Stack>
                    </form>
                )}
            </Formik>
        </>
    )
}