import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import * as Store from '../store/slices'
import { BaseButton } from '../components/buttons'
import { EditOrder, RoomsReview } from '../components/forms'
import { FileDrop } from '../components/inputs'
import { BaseGrid, BasePage, Card, Confirmation, Row, Table } from '../components/layout'
import { FileRow, MessageDrawer } from '../components/ui'
import { Order, S3FileInfo, Thread } from '../types'
import { OrderStatus, UserRoles, UserStatus } from '../types/enums'
import { ApiUtility, Endpoints, FormattingUtility, StorageUtility } from '../utilities'
import { urls } from '.'
import MarkChatUnreadRoundedIcon from '@mui/icons-material/MarkChatUnreadRounded'
import { CircularProgress, IconButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '../hooks'

const PAST_ORDER_STATUSES = [OrderStatus.Archived, OrderStatus.Cancelled, OrderStatus.Delivered]

enum UIState {
    Loading = 1,
    CurrentOrders = 2,
    PastOrders = 3,
    Detail = 4,
    Edit = 5,
    Success = 6,
}

type LoadingStates = {
    cabEntryFiles: boolean
    invoices: boolean
    sitePlanFiles: boolean,
    orderSubscription: boolean,
}

type OrderFileTypes = 'cabentry' | 'invoices' | 'plans'

export default function Page() {
    const history = useHistory()
    const query = useQuery()
    const pastOrders = history.location.pathname === urls.pastOrders
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(Store.getCurrentUser)
    const orders = useAppSelector(Store.getOrders).filter(el => pastOrders 
        ? PAST_ORDER_STATUSES.includes(el.status) 
        : !PAST_ORDER_STATUSES.includes(el.status))
    const threads = useAppSelector(Store.getThreads)
    const [state, setState] = useState<UIState>(UIState.CurrentOrders)
    const [order, setOrder] = useState<Order | null>(null)
    const [sitePlanFiles, setSitePlanFiles] = useState<S3FileInfo[] | null>(null)
    // const [cabEntryFiles, setCabEntryFiles] = useState<S3FileInfo[] | null>(null)
    const [invoiceFiles, setInvoiceFiles] = useState<S3FileInfo[] | null>(null)
    const [loading, setLoading] = useState<LoadingStates>({ sitePlanFiles: false, cabEntryFiles: false, invoices: false, orderSubscription: false, })
    const [drawerOpen, toggleDrawer] = useState<boolean>(false)
    const showDeleteFile = currentUser?.role && [UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService].includes(currentUser.role)
    const isEditOrderVisible = useMemo(() => {
        if (!order || !currentUser) {
            return false
        }

        if ([UserRoles.Admin, UserRoles.Accountant, UserRoles.CustomerService].includes(currentUser.role)) {
            return true
        }

        return [OrderStatus.Open, OrderStatus.Submitted, OrderStatus.ReadyForReview].includes(order.status)
    }, [currentUser, order])
    
    const OrderColumnDefinitions: GridColDef[] = [
        { field: 'created', headerName: 'Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.created) },
        { field: 'status', headerName: 'Status', flex: 1, renderCell: ({ row }) => getPrettyStatus(row.status) },
        { field: 'deliveryDate', headerName: 'Delivery Date', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatISODate(row.deliveryDate) },
        { field: 'salePrice', headerName: 'Cost', flex: 1, valueGetter: ({ row }) => FormattingUtility.formatMoney(row.salePrice, '-') },
        { field: 'notes', headerName: 'Notes', flex: 1, valueGetter: ({ row }) => !!row.notes ? 'Yes' : '-' },
        { field: 'jobName', headerName: 'Job Name', flex: 1, },
        { field: 'jobNumber', headerName: 'Job Number', flex: 1 },
    ]

    const canAccessBilling = (): boolean => {
        if (!currentUser) {
            return false
        }

        if (currentUser.status !== UserStatus.Active) {
            return false
        }

        if (currentUser.role === UserRoles.Admin || currentUser.role === UserRoles.Accountant || currentUser.role === UserRoles.Dealer) {
            return true
        }

        return false
    }

    const getPrettyStatus = (value: OrderStatus): ReactNode => {
        return (
            <span className='row' style={{ width: '100%' }}>
                <p style={{ color: FormattingUtility.getOrderStatusColor(value), fontWeight: 500 }}>{FormattingUtility.getOrderStatus(value)}</p>
            </span>
        )
    }

    const showOrder = (order: Order) => {
        setState(UIState.Detail)
        setOrder(order)
        setLoading({
            sitePlanFiles: true,
            cabEntryFiles: true,
            invoices: true,
            orderSubscription: true,
        })

        loadSupplementaryData(order)
    }

    const loadSupplementaryData = async (order: Order) => {
        if (!currentUser) {
            return
        }

        let response = await ApiUtility.Get(Endpoints.OrderUploads + '?order=' + order.uuid)
        if (response.success) {
            // if (response.data.cabentry) {
            //     setCabEntryFiles(response.data.cabentry)
            // }
            if (response.data.invoices) {
                setInvoiceFiles(response.data.invoices)
            }
            if (response.data.plans) {
                setSitePlanFiles(response.data.plans)
            }
        }

        setLoading(prev => ({
            ...prev,
            cabEntryFiles: false,
            invoices: false,
            sitePlanFiles: false,
        }))

        await loadMessages(order)
    }

    const loadMessages = async (order: Order) => {
        if (!order) {
            return
        }

        setLoading(prev => ({
            ...prev,
            orderSubscription: false,
        }))
    }

    const toggleSubscription = async () => {
        if (!order) {
            return
        }

        setLoading({
            ...loading,
            orderSubscription: true,
        })

        const matches = threads.filter(el => el.uuid === order.thread).length > 0

        if (matches) {
            await ApiUtility.Delete(Endpoints.Subscriptions, { thread: order.thread })
            dispatch(Store.setThreads(threads.filter(el => el.uuid !== order.thread)))
            setLoading({
                ...loading,
                orderSubscription: false,
            })
            return
        }

        let response = await ApiUtility.Put(Endpoints.Subscriptions, { thread: order.thread })
        if (response.success) {
            const res = await ApiUtility.Get(Endpoints.MessageThreads)
            if (res.success) {
                dispatch(Store.setThreads(res.data as Thread[]))
                setLoading({
                    ...loading,
                    orderSubscription: false,
                })
                return
            }
        }

        alert('There was an unexpected error. Please try again.')
        setLoading({
            ...loading,
            orderSubscription: false,
        })
    }

    const onFilesReceived = async (type: OrderFileTypes, list: File[]) => {
        if (!order) {
            alert('Please select an Order and try again.')
            return
        }

        setLoading({
            ...loading,
            cabEntryFiles: type === 'cabentry',
            invoices: type === 'invoices',
            sitePlanFiles: type === 'plans',
        })

        for (let file of list) {
            await StorageUtility.UploadObject(file, `orders/${order.uuid}/${type}`, file.name)
        }

        // Post a notification, so that the right people can be notified.
        await ApiUtility.Post(Endpoints.Orders, { ...order, files: true })

        loadSupplementaryData(order) // takes care of turning off loading as needed
    }

    const showEditOrderForm = () => {
        dispatch(Store.setDraftOrder(order!))
        setState(UIState.Loading)
        setTimeout(() => {
            setState(UIState.Edit)
        }, 1200)
    }

    const orderUpdated = async (order: Order) => {
        setOrder(order)
        let list: Order[] = []
        for (let o of orders) {
            if (o.idOrders === order.idOrders) {
                list.push(order)
                continue
            }

            list.push(o)
        }
        dispatch(Store.setOrders(list))
        setState(UIState.Success)
    }

    useEffect(() => {
        if (history.location.pathname === urls.orders) {
            return
        }

        const uuid = history.location.pathname.replace(urls.orders, '')
        const o = orders.find((el: Order) => el.uuid === uuid)

        if (o) {
            showOrder(o)

            if (query.get('view') === 'messages') {
                toggleDrawer(true)
            }
        }
    }, [])

    return (
        <BasePage
            title={order ? `${order.jobNumber}: ${order.jobName}` : (pastOrders ? 'Delivered Orders' : 'Current Orders')}
            floatingAction={!!order && state != UIState.Edit && (
                <IconButton className='dropshadow' onClick={() => toggleDrawer(true)} style={{ backgroundColor: 'var(--app-primary)', padding: '0.7em' }}>
                    <MarkChatUnreadRoundedIcon htmlColor='white' fontSize='large' />
                </IconButton>
            )}
            requireAuth={true}
            navFunction={(url: string) => {
                setOrder(null)
                dispatch(Store.setDraftOrder(null))
                setState(UIState.CurrentOrders)
                history.push(url)
            }}>
            {!order && (
                <div className='ui-row' style={{}}>
                    <p className='wizard-instructions'>{pastOrders ? 'Below you will find your delivered orders. Past orders are kept for a period of 1 year before being removed from the system.' : 'Below you will find the status of your current orders, including delivery date and pricing if available. Once an order is approved you can expect a delivery date within 5 days.'}</p>
                </div>
            )}
            {state === UIState.Loading && (
                <div className='ui-row flex-center' style={{ flex: 1 }}>
                    <CircularProgress />
                </div>
            )}
            {state === UIState.Edit && !!order && (
                <EditOrder
                    onCancel={() => { setState(UIState.Detail) }}
                    onSubmit={orderUpdated}
                />
            )}
            {state === UIState.Detail && !!order && (
                <>
                    {isEditOrderVisible && (
                        <div className='ui-row' style={{ width: '100%', justifyContent: 'flex-end' }}>
                            <BaseButton text='Edit' leftIcon='edit' onClick={showEditOrderForm} />
                        </div>
                    )}
                    <div className='ui-row' style={{ width: '100%' }}>
                        <div className='column' style={{ width: '100%' }}>
                            <div className='grid-container'>
                                <div className='grid'>
                                    <Card title='Dealer'>
                                        <Table>
                                            <Row label='Dealer' value={order.dealership.name} />
                                            <Row label='Dealer #' value={order.dealership.dealerNumber} />
                                            <Row label='Location' value={order.location.name} />
                                            <Row blank />
                                            <Row label='Address' value={FormattingUtility.FormatAddressParts({ address1: order.location.address1, address2: order.location.address2 })} />
                                            <Row label='' value={FormattingUtility.FormatAddressParts({ city: order.location.city, state: order.location.state, zip: order.location.zip })} />
                                            <Row blank />
                                            <Row label='Phone' value={FormattingUtility.formatPhoneNumber(order.location.phoneNumber)} />
                                            <Row label='Fax' value={FormattingUtility.formatPhoneNumber(order.location.faxNumber)} />
                                        </Table>
                                    </Card>
                                    <Card title='Delivery' rightHeader={<p >Estimated Delivery: {FormattingUtility.formatISODate(order.deliveryDate, 'MMMM d, yyyy', 'N/A')}</p>}>
                                        <Table>
                                            <Row label='Job Name' value={order.jobName} />
                                            <Row label='PO Number' value={order.purchaseOrder} />
                                            <Row label='Status' value={<p style={{ color: FormattingUtility.getOrderStatusColor(order.status), fontWeight: 500 }}>{FormattingUtility.getOrderStatus(order.status)}</p>} />
                                            <Row blank />
                                            <Row label='Address' value={FormattingUtility.FormatAddressParts({ address1: order?.deliveryAddress1, address2: order?.deliveryAddress2 })} />
                                            <Row label='' value={FormattingUtility.FormatAddressParts({ city: order?.deliveryCity, state: order?.deliveryState, zip: order?.deliveryZip })} />
                                            <Row blank />
                                            <Row label='Contact' value={order.deliveryContact} />
                                            <Row label='Phone' value={FormattingUtility.formatPhoneNumber(order.deliveryPhone)} />
                                            <Row label='Email' value={order.deliveryEmail} />
                                        </Table>
                                    </Card>
                                    <Card title='Rooms' error={order.rooms.length === 0} maxHeight='300px'>
                                        <RoomsReview allowEdits={false} rooms={order.rooms} />
                                    </Card>
                                    <Card title='Billing'>
                                        <Table>
                                            <Row label='Cost' value={FormattingUtility.formatMoney(order.salePrice, '-')} />
                                            <Row label='Shipping Cost' value={FormattingUtility.formatMoney(order.shippingCost, '-')} />
                                            <Row label='Total Price' value={FormattingUtility.formatMoney(order.total, '-')} />
                                        </Table>
                                        {canAccessBilling() && (
                                            <>
                                                <h5 style={{ marginTop: '1em' }}>Invoice</h5>
                                                {invoiceFiles && invoiceFiles.map((el) => (
                                                    <FileRow key={el.id} file={el} onClick={() => window.open(el.url, '_blank')} onDelete={!!showDeleteFile && (() => setInvoiceFiles(invoiceFiles.filter(file => file.id !== el.id)))} />
                                                ))}
                                                <FileDrop name='OrderInvoice' fullHeight={false} accept='.pdf' filesReceived={(list) => onFilesReceived('invoices', list)} />
                                            </>
                                        )}
                                    </Card>
                                    <Card title='Notes'>
                                        <p style={{ whiteSpace: 'pre-wrap' }}>{order.notes}</p>
                                    </Card>
                                    <Card title='Site Plans' loading={loading.sitePlanFiles}>
                                        {sitePlanFiles && sitePlanFiles.map((el) => (
                                            <FileRow key={el.id} file={el} onClick={() => window.open(el.url, '_blank')} onDelete={!!showDeleteFile && (() => setSitePlanFiles(sitePlanFiles.filter(file => file.id !== el.id)))} />
                                        ))}
                                        <FileDrop name='SitePlans' fullHeight={false} accept='.pdf' filesReceived={(list) => onFilesReceived('plans', list)} />
                                    </Card>
                                    {/* <Card title='CabEntry Document' loading={loading.cabEntryFiles}>
                                        {cabEntryFiles && cabEntryFiles.map((el) => (
                                            <FileRow key={el.id} file={el} onClick={() => window.open(el.url, '_blank')} onDelete={!!showDeleteFile && (() => setCabEntryFiles(cabEntryFiles.filter(file => file.id !== el.id)))} />
                                        ))}
                                        <FileDrop name='CabEntry' fullHeight={false} accept='.pdf' filesReceived={(list) => onFilesReceived('cabentry', list)} />
                                    </Card> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ui-row column'>
                        <p className='subtle'>Order Created: {FormattingUtility.formatISODate(order.created, 't ZZZZ, MMMM d, yyyy')}</p>
                        <p className='subtle'>Last Updated: {FormattingUtility.formatISODate(order.modified, 't ZZZZ, MMMM d, yyyy')}</p>
                    </div>
                </>
            )}
            {state === UIState.CurrentOrders && (
                <div className='ui-row'>
                    <div className='dashboard-card' style={{ width: '100%' }}>
                        <div className='row' style={{ justifyContent: 'space-between' }}>
                            <p className='card-header'>{pastOrders ? 'Delivered Orders' : 'Current Orders'}</p>
                        </div>
                        <BaseGrid
                            columns={OrderColumnDefinitions}
                            data={orders}
                            idKey={'idOrders'}
                            onRowClick={({ row }) => showOrder(row as Order)}
                        />
                    </div>
                </div>
            )}
            {state === UIState.Success && (
                <Confirmation
                    actionText='Return to Order'
                    message='The edits to this order have been successfully saved.'
                    onConfirm={() => showOrder(order!)}
                    title='Order edits saved!'
                />
            )}
            <MessageDrawer
                isOpen={drawerOpen}
                onClose={() => toggleDrawer(false)}
                thread={order?.thread}
            />
        </BasePage>
    )
}