import { Autocomplete, TextField } from '@mui/material'
import { DropDownItem } from './Dropdown'
import { RequiredIndicator } from '../ui'

interface ComboboxProps {
    defaultValue: string
    fullWidth?: boolean
    error?: any
    items: DropDownItem[]
    label?: string
    name: string
    onSelect: (item: DropDownItem | null) => void
    optional?: boolean
    placeholder?: string
}

const ComboInput = (props: ComboboxProps) => {
    const label = (props.label || '') + (props.error ? ': ' + props.error : '')

    return (
        <div style={{width: props.fullWidth ? '100%' : 150}}>
            {props.label &&
                <div className='input-label' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <p className='input-label' style={{ color: props.error ? '#FF0000' : 'var(--app-text-dark)' }}>{label}{!props.optional && (<RequiredIndicator />)}</p>
                    {props.optional && <p className='subtle'>Optional</p>}
                </div>
            }
            <Autocomplete
                value={props.items.find(item => item.label === props.defaultValue)}
                disablePortal
                id={props.name}
                fullWidth
                options={props.items}
                onChange={(e, item) => props.onSelect(item)}
                autoComplete
                blurOnSelect
                style={{
                    backgroundColor: 'white',
                    border: '1px solid var(--ui-light-blue)',
                    borderRadius: '0.5em',
                    padding: '0px',
                    margin: 0,
                }}
                isOptionEqualToValue={(option, value) => option?.label === value?.label}
                getOptionKey={(option) => option.key}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={props.placeholder}
                        type='text'
                        style={{
                            border: '1px solid var(--ui-light-blue)',
                            borderRadius: '0.5em',
                            height: '2.5em',
                            backgroundColor: 'white',
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                border: 'none',
                                display: 'flex',
                                minHeight: '100%',
                                padding: '0px 5px',
                            }
                        }}
                    />
                )}
            />
        </div>
    )
}

export default ComboInput